<div *ngIf="isMobile">

</div>
<div class="container-fluid footer" *ngIf="!isMobile">
    <div class="row justify-content-end">
        <!-- Logos réseaux sociaux -->
        <div class="col-auto text-center mb-3 logos">
            <a href="https://discord.gg/8kGHAWRu9X" target="_blank"><i class="fa-brands fa-discord fa-xl"></i></a>
            <a href="https://twitter.com/Weebz_fr" target="_blank"><i class="fa-brands fa-x-twitter fa-xl"></i></a>
            <a href="https://www.instagram.com/weebz_officiel/" target="_blank"><i class="fa-brands fa-instagram fa-xl"></i></a>
        </div>
    </div>

    <div class="row">
        <!-- Logo -->
        <div class="col-md-3 text-center mb-3">
            <img src="../../assets/logo_text.png" alt="Logo - Weebz" width="200">
        </div>
        <!-- Liens -->
        <div class="col-md-3">
            <ul class="list-unstyled">
                <li><a class="footer-text" routerLink="accueil">Accueil</a></li>
                <li><a class="footer-text" routerLink="mentions-legales/reglement">Règlement</a></li>
                <li><a class="footer-text" routerLink="mentions-legales/litiges">Litiges</a></li>
                <li><a class="footer-text" routerLink="mentions-legales/cgv">Conditions Générales de Vente</a></li>
                <li><a class="footer-text" routerLink="mentions-legales/cgu">Conditions Générales d'Utilisation</a></li>
            </ul>
        </div>
        <!-- Catégories -->
        <div class="col-md-3">
            <ul class="list-unstyled">
                <li><a class="footer-text" [routerLink]="['/search']" [queryParams]="{ type:'manga'}" >Manga</a></li>
                <li><a class="footer-text" [routerLink]="['/search']" [queryParams]="{ type:'webtoon'}">Webtoon</a></li>
                <li><a class="footer-text" [routerLink]="['/search']" [queryParams]="{ type:'lightnovel'}">Light Novels</a></li>
            </ul>
        </div>
        <!-- Adresse et mail -->
        <div class="col-md-3">
            <p>
                9 Rue Charles Fourier, 91000 Évry-Courcouronnes<br>
                <a href="mailto:contact@weebz.fr">contact&#64;weebz.fr</a>
            </p>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-12 text-center bottom-footer">
            &copy; 2023 Weebz. Tous droits réservés.
        </div>
    </div>
</div>
