import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mon-panier',
  templateUrl: './mon-panier.component.html',
  styleUrls: ['./mon-panier.component.scss']
})
export class MonPanierComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
