<div id="container">
    <img id="logo" src="../../../assets/logo_text.png" alt="Logo - Weebz" routerLink="accueil">
    <div id="utils">
        <a routerLink="/connexion" *ngIf="!isLoggedIn">Connexion</a>
        <i class="fa-solid fa-user-check fa-xl" routerLink="my-profile" *ngIf="isLoggedIn"></i>
        <i class="fa-regular fa-bookmark fa-xl" routerLink="/watchlist" *ngIf="isLoggedIn" ></i>
        <i class="fa-solid fa-bars fa-xl" (click)="toggleMenu()"></i>
    </div>
</div>
<div id="side-menu-background" *ngIf="isMenuOpen">
    <div id="side-menu">
        <i class="fa-solid fa-times fa-2x" (click)="toggleMenu()"></i>
        <h2>Menu</h2>
        <div class="line"></div>
        <div id="nav-links">
            <a routerLink="accueil" (click)="toggleMenu()">Accueil</a>
            <a [routerLink]="['search']" [queryParams]="{ type : 'manga'}" (click)="toggleMenu()">Mangas</a>
            <a [routerLink]="['search']" [queryParams]="{ type : 'webtoon'}" (click)="toggleMenu()">Webtoon</a>
            <a [routerLink]="['search']" [queryParams]="{ type : 'lightnovel'}" (click)="toggleMenu()">LightNovel</a>
            <a routerLink="shop" (click)="toggleMenu()">Boutique</a>
            <a routerLink="beta-enroll" (click)="toggleMenu()">Beta</a>
            <a *ngIf="isLoggedIn" (click)="logout()">Se déconnecter</a>
        </div>
        <div class="line"></div>
        <div id="menu-footer">
            <a routerLink="mentions-legales/cgu" (click)="toggleMenu()">Conditions générales d'utilisation</a>
            <a routerLink="mentions-legales/cgv" (click)="toggleMenu()">Conditions générales de vente</a>
        </div>
        <div class="line"></div>
        <p>Weebz vous souhaite une bonne lecture :)</p>
    </div>
</div>
