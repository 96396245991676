import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-artwork-preview',
  templateUrl: './artwork-preview.component.html',
  styleUrls: ['./artwork-preview.component.scss']
})
export class ArtworkPreviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
