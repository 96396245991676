<div class="header-container">
    <div class="row header-content">
  
      <!-- Logo -->
      <div class="header-logo-container">
        <a routerLink="accueil" >
          <img src="../../assets/logo_text.png" alt="Weebz-Logo" class="img-fluid" id="weebz-header-logo">
        </a>
      </div>
  
      <!-- Liens principaux -->
      <div class=" d-flex justify-content-center main-links align-items-center fs-5 fs-md-3 fs-lg-1">
          <!-- Dropdown pour "Catalogue" -->
          <div class="dropdown justify-content-center header-button">
            <div class="dropdown-container">
              <span routerLink="search" class="text-button">Catalogue</span>
              <div class="dropdown-content">
                <div class="dropdown-item">
                  <a [routerLink]="['/search']" [queryParams]="{type: 'manga'}">Manga</a>
                </div>
                <div class="dropdown-item">
                  <a [routerLink]="['/search']" [queryParams]="{type: 'webtoon'}">Webtoon</a>
                </div>
                <div class="dropdown-item">
                  <a [routerLink]="['/search']" [queryParams]="{type: 'lightnovel'}">Light Novel</a>
                </div>
                
              </div>
            </div>          
          </div>
  
          <!-- Lien "Blog" -->
          <div class="justify-content-center header-button">
          <span routerLink="shop" class="ml-3 text-button">Boutique</span>
          </div>
  
          <!-- Lien "Publier" -->
          <div class="justify-content-center header-button">
          <span routerLink="beta-enroll" class="ml-3 text-button">Bêta</span>
          </div>
      </div>
  
      <div class="row ml-auto right-side-container align-items-center">
        <div class="col-3 publish-container">
          <button routerLink="/upload/create-artwork" class="publish-button">Publier</button>
        </div>
        <div class="icons-container d-flex align-items-center">
          <div class="col-3 search-container">
            <app-search-header></app-search-header>
          </div>
          <div class="col-3 favorite-container " >
            <a routerLink="/watchlist"><i class="header-icon fa-regular fa-bookmark fa-xl"></i></a>
          </div>
          <div class="col-3 login-container ">
              <a routerLink="/connexion"><p *ngIf="!loggedIn" class="header-icon fa-regular fa-xl fa-user"></p></a>
              <app-connected-dropdown *ngIf="loggedIn" class="connected-dropdown" #connectedDropdown></app-connected-dropdown>
          </div>
        </div>
      </div>
    </div>
</div>
  
