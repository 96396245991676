<div class="container-fluid main-container">
    
    <div class="main-content">
        <div class="header">
            <div class="row d-flex align-items-center">
                <div class="title-container"><h2 class="title">Weebz news</h2></div>
                <div><i class="fa-regular fa-newspaper fa-4x"></i></div>
            </div>
        </div>
        <h3>Les dernières news</h3>
        <div class="row upside-row">
            <div class="image-container col-3">
                <img src="../../../../assets/fixtures/accueil/news-16-9.png">
            </div>
            <div class="image-container col-3">
                <img src="../../../../assets/fixtures/accueil/news-16-9-2.png">
            </div>
            <div class="image-container col-3">
                <img src="../../../../assets/fixtures/accueil/weebz-news-16-9-3.png">
            </div>
            <div class="image-container col-3">
                <img src="../../../../assets/fixtures/accueil/news-16-9-4.png">
            </div>
        </div>
        <h3>Top news Blog</h3>
        <div class="row downside-row">
            <div class="image-container col-4">
                <img src="../../../../assets/fixtures/accueil/news-16-9-2.png">
            </div>
            <div class="image-container col-4">
                <img src="../../../../assets/fixtures/accueil/weebz-news-16-9-3.png">
            </div>
            <div class="image-container col-4">
                <img src="../../../../assets/fixtures/accueil/news-16-9-4.png">
            </div>
        </div>
    </div>
</div>
